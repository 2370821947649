import { Auth } from '../firebase/firebaseConfig'

let endpoint = "http://localhost:8080"
if(process.env.NODE_ENV==='production') endpoint = "/api"

export function getData(req,func){
  fetch(endpoint+req.path, fetchoption(req.data)).then(function(response) {
    if(response.ok) return response.text();
    else throw new Error('ng response');
  }).catch((e) => {
    console.log("api error : "+e.message)
    return '{"error":"api error : '+e.message+'"}'
  }).then( responseBodyAsText => {
    try { return JSON.parse(responseBodyAsText); }
    catch (e) {
      console.log("unparsable response : "+e.message)
      return {error:e.message};
    }
  }).then(function(res) {
    if(res.a) authCookie(req,func);
    else func(res);
  });
}

function authCookie(req,func){
  Auth.currentUser.getIdToken(true).then(function(idToken) {
    fetch(endpoint+"/c", fetchoption({token: idToken})).then(function(response) {
      if(response.ok) return response.text();
      else throw new Error('ng response');
    }).then( responseBodyAsText => {
      try { return JSON.parse(responseBodyAsText); }
      catch (e) { throw new Error('unparsable response'); }
    }).then(function(res) {
      getData(req,func);
    }).catch(function(e) { console.error('api error : ', e.message); });
  }).catch(function(e) { console.error('gcp error : ', e.message); });
}

function fetchoption(data){
  let options = {
    method: "POST", mode: "cors", cache: "no-cache", credentials: "include",
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8', },
    body: Object.keys(data).map((key)=>key+"="+encodeURIComponent(data[key])).join("&"),
  };
  return options;
}
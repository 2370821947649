import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom'

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  bar: {
    backgroundColor: "#ddd",
    color: "#333",
    height: 20,
  },
  link: {
    textDecoration: "none",
    color: "black",
  }
};

class MenuAppBar extends React.Component {
  state = {
    anchorEl: null,
  };
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    let toolname = "";
    this.props.applist.map((r)=>{
      if(r.path===window.location.pathname){
        toolname += r.title+" - "
        return true
      }
      return false
    })
    toolname += "R4";

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.bar}>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
                aria-haspopup="true" onClick={this.handleMenu}
                aria-owns={open ? 'menu-appbar' : undefined}
              >
              <MenuIcon />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorEl} open={open} onClose={this.handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
              transformOrigin={{ vertical: 'top', horizontal: 'left', }}
            >
              <MenuItem onClick={this.handleClose}><Link to='/' className={classes.link}>Home</Link></MenuItem>
              {this.props.applist.map((r,i)=>
                <MenuItem key={"menu"+i} onClick={this.handleClose}><Link to={r.path} className={classes.link}>{r.title}</Link></MenuItem>
              )}
              <MenuItem onClick={this.props.logout}>Logout</MenuItem>
            </Menu>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              <span>{toolname}</span>
            </Typography>
            <Avatar title={this.props.user.displayName} alt={this.props.user.displayName} src={this.props.user.photoURL} />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  changeApp: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuAppBar);

import React, { Component } from 'react';
import './App.css';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import { Auth, DatabaseRef } from './firebase/firebaseConfig'
import MenuAppBar from './components/MenuAppBar.js';
import Users from './components/Users.js';
import Data from './components/Data.js';
import Log from './components/Log.js';
import GoogleAlerts from './components/GoogleAlerts.js';
import Topics from './components/Topics.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BrowserRouter, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {
  state = {
    auth: null,
    user: null,
    app: "",
  }
  componentWillMount() {
    Auth.onAuthStateChanged( (user) => {
      this.setState({auth: (user) ? 1 : 0})
      if(user){
        let self = this;
        DatabaseRef.child('/users/'+user.uid).once('value',function(dataSnapshot) {
          if(dataSnapshot.val()==null){
            DatabaseRef.child('/users/'+user.uid).update({"email" : user.email,"name" : user.displayName,'privilege' : 0,});
            self.setState({auth: 1,})
            DatabaseRef.child('/options/privileges').once('value',function(dataSnapshot2) {
              let emails = dataSnapshot2.val().split(',');
              if(emails.indexOf(user.email)!==-1){
                DatabaseRef.child('/users/'+user.uid).update({'privilege' : 1,});
                self.setState({auth: 2,})
              }
            })
          }else{
            let data = dataSnapshot.val()
            if('name' in data === false || data.name !== user.displayName){
              data.name = user.displayName
              DatabaseRef.child('/users/'+user.uid).update(data);
            }
            self.setState({auth: dataSnapshot.val().privilege+self.state.auth,})
          }
        })
      }
    })
  }
  componentDidMount() {
    Auth.onAuthStateChanged(user => {
      this.setState({ user })
    })
  }
  login() {
    const provider = new firebase.auth.GoogleAuthProvider()
    Auth.signInWithRedirect(provider)
  }
  logout() {
    Auth.signOut()
  }
  render() {
    let applist = [
      {path:"/users",title:"Users"},
      {path:"/topics",title:"Topics"},
      {path:"/googlealerts",title:"GoogleAlerts"},
      {path:"/data",title:"Data"},
      {path:"/log",title:"Log"},
    ]
    let Routes;
    if(this.state.auth === 2){ //admin Sign in
      Routes = <div>
        <BrowserRouter>
          <div>
            <MenuAppBar user={this.state.user} logout={this.logout} applist={applist}/>
            <Route key={"r0"} path={applist[0].path} render={({props}) => <Users user={this.state.user} {...props} />} />
            <Route key={"r1"} path={applist[1].path} render={({props}) => <Topics user={this.state.user} {...props} />} />
            <Route key={"r2"} path={applist[2].path} render={({props}) => <GoogleAlerts user={this.state.user} {...props} />} />
            <Route key={"r3"} path={applist[3].path} render={({props}) => <Data user={this.state.user} {...props} />} />
            <Route key={"r4"} path={applist[4].path} render={({props}) => <Log user={this.state.user} {...props} />} />
            <Route exact path='/' component={Home} />
          </div>
        </BrowserRouter>
      </div>;
    }else{
      let button = <CircularProgress />;
      if(this.state.auth === 0) button = <Button variant="contained" color="primary" onClick={this.login}>Login</Button>;
      else if(this.state.auth === 1) button = <Button variant="contained" color="primary" onClick={this.logout}>Logout</Button>;
      Routes = <div className="login">{button}</div>;
    }
    return (
      <MuiThemeProvider theme={theme}>
        <div>
        { Routes }
        </div>
      </MuiThemeProvider>
    );
  }
}

const Home = () => (
  <div>
  </div>
)

export default App;
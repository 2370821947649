import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as MOD from '../common/modules.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import SkipPrevious from '@material-ui/icons/SkipPrevious';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import FastRewind from '@material-ui/icons/FastRewind';
import FastForward from '@material-ui/icons/FastForward';
import SkipNext from '@material-ui/icons/SkipNext';
import { DatabaseRef } from '../firebase/firebaseConfig'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => { this.props.onChangePage(event, 0); };
  handleBack100ButtonClick = event => { this.props.onChangePage(event, this.props.page - 100); }
  handleBackButtonClick = event => { this.props.onChangePage(event, this.props.page - 1); };
  handleNextButtonClick = event => { this.props.onChangePage(event, this.props.page + 1); };
  handleNext100ButtonClick = event => { this.props.onChangePage(event, this.props.page + 100); }
  handleLastPageButtonClick = event => {
    this.props.onChangePage( event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1), );
  };
  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;
    return (
      <div className={classes.root}>
        <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
          {theme.direction === 'rtl' ? <SkipNext /> : <SkipPrevious />}
        </IconButton>
        <IconButton onClick={this.handleBack100ButtonClick} disabled={page-100 < 0} aria-label="-100 Page" >
          {theme.direction === 'rtl' ? <FastForward /> : <FastRewind />}
        </IconButton>
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page" >
          {theme.direction === 'rtl' ? <ArrowRight /> : <ArrowLeft />}
        </IconButton>
        <IconButton onClick={this.handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page" >
          {theme.direction === 'rtl' ? <ArrowLeft /> : <ArrowRight />}
        </IconButton>
        <IconButton onClick={this.handleNext100ButtonClick} disabled={page+100 >= Math.ceil(count / rowsPerPage) - 1} aria-label="+100 Page" >
          {theme.direction === 'rtl' ? <FastRewind /> : <FastForward />}
        </IconButton>
        <IconButton onClick={this.handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page" >
          {theme.direction === 'rtl' ? <SkipPrevious /> : <SkipNext />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  spacer: {
    flex: "0",
  }
});

export class Log extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      users: [],
      count: 0,
      page: 0,
      rowsPerPage: 10,
      loading: false,
      error: null,
      openDialog: false,
      dialog: '',
    };
  }
  gets(rowsPerPage,page){
    this.setState({loading:true});
    MOD.getData({path:"/log",data:{limit:rowsPerPage,page:page,uid:this.props.user.uid}},function(res){
      if(res.error){
        console.error(res.error);
        this.setState({error:res.error});
      }else{
        let users = this.state.users
        res.data.map((u,key)=>{
          let v = JSON.parse(u.result)
          if(v.user.uid in users === false) users[v.user.uid] = ''
          return false
        })
        Object.keys(users).forEach((id,key)=>{
          if(this.state.users[id]===''){
            DatabaseRef.child('users').child(id).once('value',function(snapshot) {
              if(snapshot.val()!==null){
                let users = this.state.users
                users[id] = snapshot.val().name
                this.setState({users:users})
              }
            }.bind(this))
          }
        })
        this.setState({loading:false,data:res.data,users:users,count:res.all,page:Number(res.page)});
      }
    }.bind(this))
  }
  componentDidMount() {
    this.gets(this.state.rowsPerPage,this.state.page)
  }
  handleChangePage = (event, page) => {
    this.gets(this.state.rowsPerPage,page)
  }
  dateFormater(date) {
    return date.getFullYear()
      +"/"+("000"+(date.getMonth()+1)).slice(-2)+"/"+("000"+date.getDate()).slice(-2)
      +" "+("000"+date.getHours()).slice(-2)+":"+("000"+date.getMinutes()).slice(-2)
      +":"+("000"+date.getSeconds()).slice(-2)
  }
  handleDialogOpen = event => {
    this.setState({ openDialog: true, dialog: event.currentTarget.value });
  }
  handleDialogClose = () => {
    this.setState({ openDialog: false });
  }
  render() {
    const { classes } = this.props;
    
    let view = []
    
    this.state.data.map((u,key)=>{
      let d = {id:u.id,type:u.type,result:u.result,created:u.created,}
      let v = JSON.parse(u.result);
      d.action = v.action
      d.uid = v.user.uid
      view.push(d)
      return false
    })
    return (
      <div className="nmlBox">
        {(this.state.loading?<div className="loading"><CircularProgress /></div>:"")}
        <Snackbar open={(this.state.error==null?false:true)} anchorOrigin={{ vertical: 'bottom',horizontal: 'left', }}>
          <SnackbarContent className={classes.error} message={<span>{this.state.error}</span>} />
        </Snackbar>
        <Dialog open={this.state.openDialog} onClose={this.handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Log</DialogTitle>
          <DialogContent><DialogContentText id="alert-dialog-description">{this.state.dialog}</DialogContentText></DialogContent>
          <DialogActions><Button onClick={this.handleDialogClose} color="primary" autoFocus>close</Button></DialogActions>
        </Dialog>
        {(view.length===0?<div>no data</div>:
          <span>
            <TablePagination component="div" rowsPerPageOptions={[]}
              count={this.state.count} rowsPerPage={this.state.rowsPerPage} page={this.state.page}
              backIconButtonProps={{'aria-label': 'Previous Page',}}
              nextIconButtonProps={{'aria-label': 'Next Page',}}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActionsWrapped}
              classes={{ spacer: classes.spacer, }}
            />
            <Table className="log">
              <TableBody>
                {view.map((u, i) =>
                  <TableRow key={i}>
                    <TableCell className="cell">{u.created}</TableCell>
                    <TableCell className="cell">{u.type}:{u.action}</TableCell>
                    <TableCell className="cell">{this.state.users[u.uid]}</TableCell>
                    <TableCell className="cell">
                      <Button size="small" onClick={this.handleDialogOpen} value={u.result}>log</Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </span>
        )}
      </div>
    );
  }
}

Log.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Log);
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DatabaseRef } from '../firebase/firebaseConfig'

const styles = theme => ({
});

export class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }
  componentWillMount() {
    DatabaseRef.child('users').on('child_added', (snapshot) => {
      const u = snapshot.val()
      let usrs = this.state.users
      usrs.push({
        'email' : u.email,
        'privilege' : u.privilege,
      })
      this.setState({
        users : usrs
      });
    })
  }
  render() {
    return (
      <div className="nmlBox">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Privilege</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.users.map((u, i) =>
              <TableRow key={i}>
                <TableCell>{u.email}</TableCell>
                <TableCell>{u.privilege}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as MOD from '../common/modules.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

export class GoogleAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      data: [],
      domains: [],
      exdomains: [],
      exstrs: [],
      inurls: [],
      check: {},
      pickup: {},
      loading: false,
      error: null,
    };
  }
  getall(){
    this.setState({loading:true});
    MOD.getData({path:"/galert",data:{uid:this.props.user.uid}},function(res){
      if(res.error){
        console.log(res.error);
        this.setState({error:res.error});
      }else{
        this.setState({loading:false,data:res.data
          ,domains:res.domains,exdomains:res.exdomains,exstrs:res.exstrs,inurls:res.inurls});
      }
    }.bind(this))
  }
  componentDidMount() {
    this.getall()
  }
  check(ids){
    this.setState({check:Object.assign(this.state.check, ids)});
    for (let key in ids) {
      MOD.getData({path:"/galert/"+key,data:{s:'check',uid:this.props.user.uid}},function(res){
        let check = this.state.check
        if(res.affected>0) check[key] = true
        else delete check[key]
        this.setState({check:check});
      }.bind(this));
    }
  }
  handleStartClick = event => {
    let key = event.currentTarget.value
    let ids = {}
    ids[key] = false
    this.setState({pickup:Object.assign(this.state.pickup, ids)});
    MOD.getData({path:"/galert/"+key,data:{s:'pickup',uid:this.props.user.uid}},function(res){
      let pickup = this.state.pickup
      if(res.affected>0) pickup[key] = true
      else delete pickup[key]
      this.setState({pickup:pickup});
    }.bind(this));
  }
  handleDomainkClick = event => {
    let domain = event.currentTarget.value
    MOD.getData({path:"/galert/domain",data:{domain:domain,uid:this.props.user.uid}},function(res){
      let exdomains = this.state.exdomains
      if(res.affected>0) exdomains.push(domain)
      this.setState({exdomains:exdomains});
    }.bind(this));
  }
  handleCheckClick = event => {
    let ids = {}
    ids[event.currentTarget.value] = false
    this.check(ids)
  }
  handleCheckAllClick = event => {
    let idlist = event.currentTarget.value.split(',')
    idlist.pop()
    let ids = {}
    idlist.map((v)=>{return ids[v]=false})
    this.check(ids)
  }
  render() {
    const { classes } = this.props;
    let checked = 0
    for (let key in this.state.check) {
      if(this.state.check[key]) checked++
    }
    let pickuped = 0
    for (let key in this.state.pickup) {
      if(this.state.pickup[key]) pickuped++
    }
    let view = this.state.data.filter( (value) => {
      return !(value.id in this.state.pickup)
    }).filter( (value) => {
      return !(value.id in this.state.check)
    }).slice(0,10)
    let idlist = ''
    view.map((u,key)=>{
      view[key].inlist = false
      view[key].exdomain = false
      view[key].chips = []
      if(this.state.exdomains.indexOf(u.domain)!==-1){
        view[key].chips.push(<span className="warning">exclude domain</span>)
        view[key].inlist = true
        view[key].exdomain = true
      }
      this.state.exstrs.some((v)=>{
        if(u.title.indexOf(v)>=0){
          view[key].chips.push(<span className="warning">exclude string</span>);
          view[key].inlist = true;return true;
        }
        if(u.snippet.indexOf(v)>=0){
          view[key].chips.push(<span className="warning">exclude string</span>);
          view[key].inlist = true;return true;
        }
        return false;
      });
      this.state.inurls.some((v)=>{
        if(u.url.indexOf(v)>=0){
          view[key].chips.push(<span className="info">pickup url</span>)
          return true
        }
        return false;
      });
      if(u.url.match(/.*[a-zA-Z0-9]{15,100}.+/)){
        view[key].chips.push(<span className="warning">randum string</span>)
      }
      if(Object.keys(this.state.domains).indexOf(u.domain)!==-1){
        view[key].chips.push(<span className="warning">{"domain count:"+this.state.domains[u.domain]}</span>);
      }
      try{ u.url = decodeURI(u.url); }catch{}
      if(view[key].inlist) idlist+=u.id+","
      return false
    })
    return (
      <div className="nmlBox">
        {(this.state.loading?<div className="loading"><CircularProgress /></div>:"")}
        <Snackbar open={(this.state.error==null?false:true)} anchorOrigin={{ vertical: 'bottom',horizontal: 'left', }}>
          <SnackbarContent className={classes.error} message={<span>{this.state.error}</span>} />
        </Snackbar>
        {(view.length===0?<div>no data</div>:
          <Table className="googleAlerts">
            <TableHead>
              <TableRow className="inforow">
                <TableCell className="cell"></TableCell>
                <TableCell className="cell infocell">
                  <Button onClick={this.handleCheckAllClick} value={idlist}>▼ all gray</Button>
                  all : {this.state.data.length-checked-pickuped}.
                  view : {view.length}.
                  check : {Object.keys(this.state.check).length},
                  checked : {checked}.
                  pickup : {Object.keys(this.state.pickup).length},
                  pickuped : {pickuped}.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {view.map((u, i) =>
                <TableRow key={i} className={(u.inlist?'exclude':'')}>
                  <TableCell className="cell actioncell">
                    <Button onClick={this.handleCheckClick} value={u.id}>▼</Button>
                    <Button onClick={this.handleStartClick} value={u.id}>☆</Button>
                  </TableCell>
                  <TableCell className="cell googleAlert">
                    {u.chips.map((w,j)=><span key={"warning"+j}>{w}</span>)}
                    <span className="title"><a href={u.url} target="_blank" rel="noreferrer noopener"
                      dangerouslySetInnerHTML={{__html: u.title}}></a></span>
                    <span className="published">{u.published}</span>
                    <span className="id">[{u.id}]</span>
                    <span className="url">{u.url}</span>
                    {(!u.exdomain?<Button className="domain" size="small" variant="outlined" onClick={this.handleDomainkClick} value={u.domain}>+ {u.domain}</Button>:<br />)}
                    <span className="snippet" dangerouslySetInnerHTML={{__html: u.snippet}}></span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

GoogleAlerts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoogleAlerts);
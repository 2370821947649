import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyCv6E-KJeDrVVDqa_mhCAzuFsVClcQ_cbY",
  authDomain: "c7r4-7f1ab.firebaseapp.com",
  databaseURL: "https://c7r4-7f1ab.firebaseio.com",
  projectId: "c7r4-7f1ab",
  storageBucket: "c7r4-7f1ab.appspot.com",
  messagingSenderId: "688625626361"
};

firebase.initializeApp(config);

const DatabaseRef = firebase.database().ref();
const StorageRef = firebase.storage().ref();
const StorageImagesRef = StorageRef.child('images');
const Auth = firebase.auth();

export {
  Auth,
  DatabaseRef,
  StorageRef,
  StorageImagesRef
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {getData} from '../common/modules.js';

const styles = theme => ({
});

export class Data extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      user: [],
    };
  }
  handleClick() {
    getData({path:"/hello/test",data:{uid:this.props.user.uid}},function(res){
      this.setState({user:res.name});
    }.bind(this));
  }
  render() {
    return (
      <div className="nmlBox">
        <Button variant="contained" color="primary" onClick={this.handleClick}>handleClick</Button>
        <p>name : {this.state.user}</p>
      </div>
    );
  }
}

Data.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Data);
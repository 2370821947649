import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as MOD from '../common/modules.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import SkipPrevious from '@material-ui/icons/SkipPrevious';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import FastRewind from '@material-ui/icons/FastRewind';
import FastForward from '@material-ui/icons/FastForward';
import SkipNext from '@material-ui/icons/SkipNext';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => { this.props.onChangePage(event, 0); };
  handleBack100ButtonClick = event => { this.props.onChangePage(event, this.props.page - 100); }
  handleBackButtonClick = event => { this.props.onChangePage(event, this.props.page - 1); };
  handleNextButtonClick = event => { this.props.onChangePage(event, this.props.page + 1); };
  handleNext100ButtonClick = event => { this.props.onChangePage(event, this.props.page + 100); }
  handleLastPageButtonClick = event => {
    this.props.onChangePage( event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1), );
  };
  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;
    return (
      <div className={classes.root}>
        <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
          {theme.direction === 'rtl' ? <SkipNext /> : <SkipPrevious />}
        </IconButton>
        <IconButton onClick={this.handleBack100ButtonClick} disabled={page-100 < 0} aria-label="-100 Page" >
          {theme.direction === 'rtl' ? <FastForward /> : <FastRewind />}
        </IconButton>
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page" >
          {theme.direction === 'rtl' ? <ArrowRight /> : <ArrowLeft />}
        </IconButton>
        <IconButton onClick={this.handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page" >
          {theme.direction === 'rtl' ? <ArrowLeft /> : <ArrowRight />}
        </IconButton>
        <IconButton onClick={this.handleNext100ButtonClick} disabled={page+100 >= Math.ceil(count / rowsPerPage) - 1} aria-label="+100 Page" >
          {theme.direction === 'rtl' ? <FastRewind /> : <FastForward />}
        </IconButton>
        <IconButton onClick={this.handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page" >
          {theme.direction === 'rtl' ? <SkipPrevious /> : <SkipNext />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  spacer: {
    flex: "0",
  }
});

export class Topics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      count: 0,
      page: 0,
      rowsPerPage: 10,
      loading: false,
      error: null,
    };
  }
  gets(rowsPerPage,page){
    this.setState({loading:true});
    MOD.getData({path:"/topics",data:{limit:rowsPerPage,page:page,uid:this.props.user.uid}},function(res){
      if(res.error){
        console.error(res.error);
        this.setState({error:res.error});
      }else{
        this.setState({loading:false,data:res.data,count:res.all,page:Number(res.page)});
      }
    }.bind(this))
  }
  componentDidMount() {
    this.gets(this.state.rowsPerPage,this.state.page)
  }
  handleChangePage = (event, page) => {
    this.gets(this.state.rowsPerPage,page)
  }
  dateFormater(date) {
    return date.getFullYear()
      +"/"+("000"+(date.getMonth()+1)).slice(-2)+"/"+("000"+date.getDate()).slice(-2)
      +" "+("000"+date.getHours()).slice(-2)+":"+("000"+date.getMinutes()).slice(-2)
      +":"+("000"+date.getSeconds()).slice(-2)
  }
  render() {
    const { classes } = this.props;
    
    let view = []
    this.state.data.map((u,key)=>{
      let d = {id:u.id}
      let v = JSON.parse(u.json);
      if(u.service===1){
        try{ d.url = decodeURI(v.url); }
        catch{ d.url = v.url }
        d.d = <span className="googleAlert">
          <span className="published">{this.dateFormater(new Date(v.published))}</span>
          <span className="url">{d.url}</span><br />
          <span className="title"><a href={d.url} target="_blank" rel="noreferrer noopener"
            dangerouslySetInnerHTML={{__html: v.title}}></a></span>
          <span className="snippet" dangerouslySetInnerHTML={{__html: v.content}}></span>
        </span>
      }else if(u.service===2){
        d.d = <span className="googleAlert">
          <span className="published">{d.published}</span>
          <span className="published">{this.dateFormater(new Date(u.createdOn*1000))}</span>
          <span className="url">{v.url}</span><br />
          <span className="title"><a href={v.url} target="_blank" rel="noreferrer noopener">{v.title}</a></span>
          <span className="snippet" dangerouslySetInnerHTML={{__html: v.desc}}></span>
        </span>
      }else if(u.service===3){
        v = v[0]
        d.snippet = v.text
        if('entities' in v){
          if('urls' in v.entities){
            for (let key in v.entities.urls) {
              let u = v.entities.urls[key]
              d.snippet = d.snippet.replace(u.url,'<a href="'+u.expanded_url+'" target="_blank">'+u.display_url+'</a>')
            }
          }
          if('media' in v.entities){
            for (let key in v.entities.media) {
              let u = v.entities.media[key]
              d.snippet = d.snippet.replace(u.url,'<a href="'+u.media_url_https+'" target="_blank">'+u.display_url+'</a>')
            }
          }
          if('hashtags' in v.entities){
            for (let key in v.entities.hashtags) {
              let u = v.entities.hashtags[key]
              d.snippet = d.snippet.replace("#"+u.text,'<a href="https://twitter.com/hashtag/'+encodeURI(u.text)+'5?src=hash" target="_blank">#'+u.text+'</a>')
            }
          }
        }
        d.url = "https://twitter.com/"+v.user.screen_name+"/status/"+v.id_str
        d.d = <span className="twitter">
          <span className="published">{d.published}</span>
          <span className="published">{this.dateFormater(new Date(v.created_at))}</span>
          <span className="url"><a href={d.url} target="_blank" rel="noreferrer noopener">twitter.com</a></span>
          <span className="user">by <a href={"https://twitter.com/"+v.user.screen_name} target="_blank" rel="noreferrer noopener">{v.user.name+" @"+v.user.screen_name}</a></span>
          <span className="source" dangerouslySetInnerHTML={{__html: "from "+v.source}}></span>
          <br />
          <span className="snippet" dangerouslySetInnerHTML={{__html: d.snippet}}></span>
        </span>
      }
      view.push(d)
      return false
    })
    return (
      <div className="nmlBox">
        {(this.state.loading?<div className="loading"><CircularProgress /></div>:"")}
        <Snackbar open={(this.state.error==null?false:true)} anchorOrigin={{ vertical: 'bottom',horizontal: 'left', }}>
          <SnackbarContent className={classes.error} message={<span>{this.state.error}</span>} />
        </Snackbar>
        {(view.length===0?<div>no data</div>:
          <span>
            <TablePagination component="div" rowsPerPageOptions={[]}
              count={this.state.count} rowsPerPage={this.state.rowsPerPage} page={this.state.page}
              backIconButtonProps={{'aria-label': 'Previous Page',}}
              nextIconButtonProps={{'aria-label': 'Next Page',}}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActionsWrapped}
              classes={{ spacer: classes.spacer, }}
            />
            <Table className="topics">
              <TableBody>
                {view.map((u, i) =>
                  <TableRow key={i}>
                    <TableCell className="cell">{u.d}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </span>
        )}
      </div>
    );
  }
}

Topics.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Topics);